import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
export default class HubspotSection extends React.Component {
  constructor(props) {
    super(props);

    this.isClient = typeof window !== 'undefined' && window.document?.createElement;
    this.state = {
      content: '',
    };
  }

  componentWillMount() {
    if (this.isClient) {
      let content = document.getElementById(this.props.id)?.innerHTML;
      this.setState({
        content,
      });
    }
  }

  render() {
    if (this.isClient) {
      return <Container id={this.props.id} dangerouslySetInnerHTML={{ __html: this.state.content }}></Container>;
    } else {
      return <Container id={this.props.id}>{this.props.children}</Container>;
    }
  }
}
