import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import HubspotField from '../components/HubspotField';
import HubspotSection from '../components/HubspotSection';

import {
  BlogCard,
  Footer,
  FeaturedBlogCard,
  Layout,
  Section,
  MainNavigation,
  RichText,
  Button,
  Widget,
} from '../components';

const Title = styled.h1`
  font-family: 'GT Walsheim', 'Arial', 'Helvetica', sans-serif;
  margin-bottom: 2.8rem;
  margin-top: 60px;
`;

const Listings = styled.div`
  a:hover h2 {
    text-decoration: underline;
  }
  a:hover img {
  }
`;

const Blogs = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
`;

const Paginator = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const HubspotPagination = () => (
  <Paginator>
    <Button url="{{ blog_page_link(last_page_num) }}" variation="block" label="back" color="purple" />
    <Button url="{{ blog_page_link(last_page_num) }}" variation="block" label="1" color="purple" />
    <Button url="#next" variation="block" label="Next" color="purple"></Button>
  </Paginator>
);

const PageTemplate = ({ data }) => {
  const { navItems: mainNavItems } = data.mainNav;
  const { navItems: footerNavItems } = data.footerNav;
  const { formData } = data.subscriptionForm;

  return (
    <Layout
      title={'Sparkol Stories'}
      slug={'slug'}
      metaDescription={'Tips, tutorials and guides for creating amazing videos.'}
    >
      <header>
        <MainNavigation navItems={mainNavItems} activePage={'slug'} />
      </header>
      <main>
        <HubspotSection id="blog-listing">
          <Section>
            <Listings>
              <Title>The Sparkol Blog</Title>
              <FeaturedBlogCard />
              <Blogs data-hs="blog-listing">
                <BlogCard />
              </Blogs>
            </Listings>
          </Section>
        </HubspotSection>
        <Section>
          <h2>Subscribe to our blog</h2>
          <Widget widget="Hubspot form" data={formData} />-{' '}
        </Section>
      </main>
      <Footer navItems={footerNavItems} activePage={'slug'} />
    </Layout>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PageTemplate;

export const query = graphql`
  query BlogListingPageBySlugQuery {
    mainNav: contentfulNavigation(contentful_id: { eq: "2meGLRtPVtQHM3nQ5O9ybD" }) {
      ...NavigationFragment
    }
    footerNav: contentfulNavigation(contentful_id: { eq: "4oCRZPRLFLbMWGwFsXcHjI" }) {
      ...NavigationFragment
    }
    subscriptionForm: contentfulWidget(contentful_id: { eq: "5A6V0QQFIgrQ5F3jRS8JsS" }) {
      title
      formData: data {
        formId
        portalId
      }
    }
  }
`;
